<template>
  <v-app class="app">
    <the-loading ref="loading" />
    <v-container class="content" fluid fill-height>
      <v-flex align-self-start>
        <v-layout row fill-height class="justify-center text-xs-left">
          <v-flex>
            <v-card class="content" flat>
              <v-layout>
                <v-flex xs2 pl-4>
                  <v-img
                    src="@/assets/img/vizibillLogo.png"
                    cover
                    min-height="40"
                    max-height="70"
                    min-width="90"
                    max-width="150"
                  ></v-img>
                </v-flex>
                <v-flex xs8 class="justify-center text-xs-center">
                  <h1>Pay Now</h1>
                </v-flex>
                <v-flex xs2></v-flex>
              </v-layout>
              <v-card-text mt-2 v-if="showForm" style="height:100%">
                <v-layout row>
                  <v-flex xs12 mx-2 class="justify-center text-xs-center"><b>Account Number:</b> {{ BAN }} </v-flex>
                </v-layout>
                <v-layout row v-if="clientName == 'Atlas'">
                  <v-flex xs12 mx-2 class="justify-center text-xs-center"><b>Invoice Number:</b> {{ invoiceDetails.Description }} </v-flex>
                </v-layout>
                <v-layout row v-if="clientName == 'Atlas'">
                  <v-flex xs12 mx-2 class="justify-center text-xs-center"><b>Invoice Date:</b> {{ formatDate(invoiceDetails.Date) }} </v-flex>
                </v-layout>
                <v-layout row v-if="clientName == 'Atlas'">
                  <v-flex xs12 mx-2 class="justify-center text-xs-center"><b>Invoice Balance:</b> {{ formatAmount(invoiceDetails.Balance) }} </v-flex>
                </v-layout>
                <v-layout row v-if="clientName == 'Mircom'">
                  <v-flex xs12 mx-2 class="justify-center text-xs-center"><b>Total Balance:</b> {{ formatAmount(totalAmount) }} </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs3 />
                  <v-flex xs6 my-4 class="justify-center text-xs-center"><v-divider /></v-flex>
                  <v-flex xs3 />
                </v-layout>
                <v-layout row>
                  <v-flex xs6 mr-2 class="justify-center text-xs-right"><b>Total Payment:</b></v-flex>
                  <v-flex xs1 class="justify-center text-xs-left">
                    <v-text-field
                      class="payment-amount"
                      v-model="paymentAmount"
                      box
                      background-color="#e8f0fe"
                      hide-details
                      prefix="$"
                    />
                  </v-flex>
                  <v-flex xs5></v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs12 mx-2 mt-3 class="justify-center text-xs-center">Please select a payment option.</v-flex>
                </v-layout>
                <v-layout column>
                  <v-radio-group v-if="clientName == 'Atlas'" column class="justify-center text-xs-center mb-0" v-model="paymentMethod">
                    <v-radio label="New Credit Card" value="ST"></v-radio>
                    <v-radio label="New ACH" value="AC"></v-radio>
                    <v-radio v-if="hasExistingCC" :label="`Credit Card on File: **** ${existingCCLast4}`" value="ECC"></v-radio>
                    <v-radio v-if="hasExistingACH" :label="`ACH on File: **** ${existingACHLast4}`" value="EAC"></v-radio>
                  </v-radio-group>
                  <v-radio-group v-if="clientName == 'Mircom'" column class="justify-center text-xs-center mb-0" v-model="paymentMethod">
                    <v-radio label="Visa/Mastercard" value="MO"></v-radio>
                    <v-radio label="AMEX" value="ST"></v-radio>
                    <v-radio v-if="hasExistingCC" :label="`Credit Card on File: **** ${existingCCLast4}`" value="MOECC"></v-radio>
                  </v-radio-group>
                </v-layout>
                <vizibill-moneris-form
                  ref="monerisForm"
                  v-show="paymentMethod === 'MO'"
                  :customer="BAN"
                  :clientToken="clientToken"
                  :name="invoiceDetails.AccountName1"
                  :email="emailAddress"
                  :accountToken="accountToken"
                  :invoiceNumber="invoiceDetails.Description"
                  :invoiceAmount="paymentAmount"
                  :defaultPayment="defaultPayment"
                  :userName="userName"
                  :account="account"
                />
                <vizibill-stripe-form
                  ref="stripeForm"
                  v-show="paymentMethod === 'ST'"
                  :customer="BAN"
                  :clientToken="clientToken"
                  :name="invoiceDetails.AccountName1"
                  :email="emailAddress"
                  :accountToken="accountToken"
                  :invoiceNumber="invoiceDetails.Description"
                  :invoiceAmount="paymentAmount"
                  :defaultPayment="defaultPayment"
                  :userName="userName"
                />
                <vizibill-ach-payment-form
                  ref="achForm"
                  v-show="paymentMethod === 'AC'"
                  :customer="BAN" 
                  :clientToken="clientToken"
                  :email="emailAddress"
                  :accountToken="accountToken"
                  :invoiceNumber="invoiceDetails.Description"
                  :invoiceAmount="paymentAmount"
                  :defaultPayment="defaultPayment"
                  :userName="userName"
                />
                <v-checkbox size="large" class="justify-center text-xs-center pt-4 mb-0 pb-0" v-model="defaultPayment" :label="checkboxLabel" />
                <p class="justify-center text-xs-center mb-4">By clicking <b>Pay Now</b> you agree to the <router-link to="/termsconditions">Terms & Conditions</router-link> and <router-link to="/privacypolicy">Privacy Policy.</router-link></p>
                <v-flex class="justify-center text-xs-center">
                  <a @click="cancel">Cancel</a>
                  <v-btn class="ml-4 mb-0" large color="success" @click="submitPayment">Pay Now</v-btn>
                </v-flex>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-container>
  </v-app>
</template>

<script>
import axios from "axios"
import dayjs from 'dayjs'
import store from "../../store"
import VizibillStripeForm from "../../components/PaymentConsole/VizibillStripeForm.vue"
import VizibillAchPaymentForm from "../../components/PaymentConsole/VizibillACHPaymentForm.vue"
import VizibillMonerisForm from "../../components/PaymentConsole/VizibillMonerisForm.vue"
import TheLoading from "@/components/TheLoading.vue"

export default {
  components: {
    VizibillStripeForm,
    VizibillAchPaymentForm,
    VizibillMonerisForm,
    TheLoading
  },
  data: () => ({
    valid: false,
    menu: false,
    BAN: "",
    invoiceDetails: {},
    accountToken: "",
    clientToken: "",
    userName: "",
    totalAmount: "",
    paymentAmount: "",
    clientName: "",
    paymentProvider: "",
    account: {},
    emailAddress: "",
    defaultPayment: false,
    showForm: false,
    showACH: false,
    hasLinkedAccounts: true,
    paymentMethod: 'ST',
    hasExistingACH: false,
    existingACHLast4: '',
    existingACHId: '',
    hasExistingCC: false,
    existingCCLast4: '',
    existingCCId: '',
  }),
  async mounted() {
    this.$refs.loading.loading(true)
    this.accountToken = store.state.adminPayConsoleDetails.accountToken
    this.clientToken = store.state.adminPayConsoleDetails.clientToken
    this.clientName = store.state.adminPayConsoleDetails.clientName
    if (this.clientName == 'Mircom') this.paymentProvider = 'MO'
    this.BAN = store.state.adminPayConsoleDetails.BAN
    this.emailAddress = store.state.adminPayConsoleDetails.emailAddress
    this.userName = store.state.adminPayConsoleDetails.userName
    if (store.state.adminPayConsoleDetails.invoiceDetails) {
      this.invoiceDetails = store.state.adminPayConsoleDetails.invoiceDetails
      this.paymentAmount = this.formatAmount(store.state.adminPayConsoleDetails.invoiceDetails.Balance).replace('$', '')
    } else {
      this.paymentAmount = this.formatAmount(store.state.adminPayConsoleDetails.totalAmount).replace('$', '')
    }
    this.account = store.state.adminPayConsoleDetails.account
    this.totalAmount = store.state.adminPayConsoleDetails.totalAmount
    
    await this.checkBusinessRules()
    await this.checkExistingPaymentMethods()
    this.showForm = true
    this.$refs.loading.loading(false)
  },
  computed: {
    checkboxLabel() {
      if (this.paymentMethod == 'ST') {
        return 'Sign up for autopay using this credit card.'
      } else {
        return 'Sign up for autopay using this bank account.'
      }
    },
  },
  methods: {
    async submitPayment() {
      let payment = parseFloat(this.paymentAmount.replace(',', '').replace('$', ''))
      let total = this.clientName == 'Atlas' ? parseFloat(this.invoiceDetails.Balance) : parseFloat(this.totalAmount)
      if (payment != total) {
        let diff = payment - total
        if (!confirm(`The total payment does not match the ${this.clientName == 'Atlas' ? 'invoice' : 'total'} balance. Are you sure you want to ${diff > 0 ? 'overpay' : 'underpay'} ${this.clientName == 'Atlas' ? 'this invoice' : 'total balance'} by $${Math.abs(diff).toFixed(2)}?`)) return
      }
      if (this.defaultPayment) {
        this.account.paymentMethodId = this.paymentMethod
        await axios.post(process.env.VUE_APP_PGAPI + 'api/boss40/editaccount', { "account": this.account, "payGateToken": this.clientToken })
      }
      switch (this.paymentMethod) {
        case 'ST':
          await this.$refs.stripeForm.submit('PayNow')
          break
        case 'ECC':
          await this.$refs.stripeForm.submitExistingCard('PayNow', this.existingCCId)
          break
        case 'MO':
          await this.$refs.monerisForm.submit('PayNow')
          break
        case 'MOECC':
          await this.$refs.monerisForm.submitExistingCard('PayNow', this.existingCCId)
          break
        case 'AC':
          await this.$refs.achForm.submit('PayNow')
          break
        case 'EAC':
          await this.$refs.achForm.submit('PayNow', this.existingACHId)
          break
      }
    },
    async checkExistingPaymentMethods() {
      if (this.clientName == 'Atlas') {
        await axios
          .post(process.env.VUE_APP_PGAPI + 'api/ach/read', { payGateToken: this.clientToken, BAN: this.BAN})
          .then((res) => {
            if (res.data && res.data.Result) {
              this.hasExistingACH = true
              this.existingACHLast4 = res.data.Result.accountNumber.slice(-4)
              this.existingACHId = res.data.Result.id
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
      await axios
        .post(process.env.VUE_APP_PGAPI + 'api/card/read', { payGateToken: this.clientToken, BAN: this.BAN})
        .then((res) => {
          if (res.data) {
            this.hasExistingCC = true
            this.existingCCLast4 = res.data.Result.data ? res.data.Result.data[0].card.last4 : res.data.Result.last4
            this.existingCCId = res.data.Result.data ? res.data.Result.data[0].id : res.data.Id
            if (this.existingCCLast4.length > 4) {
              this.existingCCLast4 = this.existingCCLast4.slice(-4)
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    async checkBusinessRules() {
      await axios
        .post(process.env.VUE_APP_PGAPI + 'api/boss40/getbusinessrules', { payGateToken: this.clientToken, Filters: { Rules: ["MYACH"] } })
        .then((res) => {
          if (res.data) {
            let achRule = JSON.parse(res.data).find(a => a.Id == "MYACH")
            if (achRule.Value === 'TRUE') this.showACH = true
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    cancel() {
      this.$router.push({ 
        name: 'PaymentConsole',
        query: {
          token: this.accountToken,
        }
      })
    },
    formatDate(date) {
      if (date) {
        let YYYYMMDD = dayjs(date, 'YYYY-MM-DD')
        return YYYYMMDD.format('DD MMM, YYYY')
      }
    },
    formatAmount(value) {
      var absValue = Math.abs(value)
      var returnString = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(absValue)
      return value < 0 ? '-' + returnString : returnString
    }
  }
}
</script>

<style scoped>
.app {
  width: 100%;
  background-color: #EEEEEE;
}
.content {
  position: relative;
  min-width: 1300px;
  width: 60%;
  margin: 0 auto;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: white;
}
.whitebg {
  background-color: white;
}
.action-bars {
  background-color: #f0f0f0 !important;
  min-height: 52px;
  max-height: 52px;
}
.card-title{
    font-family: Roboto, sans-serif;
    font-size: 20px;
}
.form-field-label{
    color: grey;
    font-family: Roboto, sans-serif;
    font-size: 12px;
}
.form-field{
    border-bottom-width: 1px;
    border-bottom-color: lightgray;
    border-bottom-style: solid;
}
/deep/div.v-input.v-input--selection-controls.v-input--checkbox {
  margin-top: 0px;
  padding-top: 0px;
}
/deep/ .payment-amount .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
  padding: 3px !important;
  width: 110px !important;
  height: 30px !important;
  margin-top: -5px !important;
}
/deep/ .payment-amount div.v-text-field__prefix {
  padding: 0 !important;
  margin: 0 !important;
  margin-right: 5px !important;
}
/deep/ .payment-amount div.v-text-field__slot input{
  padding: 0 !important;
  margin: 0!important;
}
/deep/.v-label.theme--light {
  color: black;
}
</style>