<template>
  <v-app class="app">
    <the-loading ref="loading" />
    <v-container class="content" fluid fill-height>
      <v-layout row fill-height class="justify-center text-xs-left">
        <v-card class="content" flat>
          <v-layout>
            <v-flex xs2 pl-4>
              <v-img
                src="@/assets/img/vizibillLogo.png"
                cover
                min-height="40"
                max-height="70"
                min-width="90"
                max-width="150"
              ></v-img>
            </v-flex>
            <v-flex xs8 pb-0 class="justify-center text-xs-center">
              <h1>Payment Console</h1>
              <h3 pt-2>{{ BAN }} - {{ account.Name1 }}</h3>
            </v-flex>
            <v-flex xs2 pr-4 class="text-xs-right">
              <v-btn icon @click="cancel"><v-icon color="grey">fa-times</v-icon></v-btn>
            </v-flex>
          </v-layout>
          <v-card-text class="table-card" mt-2 style="height:100%">
            <v-data-table
              :headers="headers"
              :items="billingHistory"
              :pagination.sync="pagination"
              :total-items="pagination.totalRecords"
              @update:pagination="getBillingHistory"
              item-key="ArDoc"
              select-all
              expand
              hide-details
            >
              <template v-slot:headers="props">
                <tr>
                  <th v-if="account.IsParent"></th>
                  <th
                    v-for="header in props.headers"
                    :key="header.text"
                    :class="header.class"
                  >{{ header.text }}</th>
                </tr>
              </template>
              <template v-slot:items="props">
                <tr :style="`font-size: 12px; background-color: ${props.expanded ? '#F5F5F5': ''}; height: ${props.item.TypeDescription == 'Invoice' && account.IsParent ? '60px' : ''}`">
                  <td v-if="account.IsParent" width="2px">
                      <v-btn
                        v-if="props.item.TypeDescription == 'Invoice' && props.item.ChildInvoices.receivables.length > 0"
                        flat
                        fab
                        depressed
                        small
                        @click="props.expanded = !props.expanded"
                      ><v-icon>{{ props.expanded ? 'fa-chevron-up': 'fa-chevron-down' }}</v-icon></v-btn>
                  </td>
                  <td style="font-weight: bold;">{{ props.item.Description }}</td>
                  <td class="text-xs-center"><v-chip
                    class="white-chip"
                    :color="getTypeColour(props.item.TypeDescription)"
                    size="small"
                    >{{ props.item.TypeDescription }}</v-chip></td>
                  <td>{{ formatDate(props.item.Date) }}</td>
                  <td class="text-xs-center"><v-chip v-if="props.item.Amount > 0 || props.item.TypeDescription != 'Invoice'" class="white-chip" size="small" :color="props.item.StatusColor">{{ props.item.StatusDetails }}</v-chip></td>
                  <td :style="`font-weight: bold; color: ${props.item.Amount < 0 ? 'red' : 'black'}`" :class="`text-xs-right`">{{ formatAmount(props.item.Amount) }}</td>
                  <td v-if="account.IsParent" :style="`font-weight: bold; color: ${props.item.ChildInvoices.totalAmount < 0 ? 'red' : 'black'}`" :class="`text-xs-right`">
                    <span v-if="props.item.TypeDescription == 'Invoice'">{{ formatAmount(props.item.ChildInvoices.totalAmount) }}</span>
                  </td>
                  <td :style="`color: ${props.item.Balance < 0 ? 'red' : 'black'}`" :class="`text-xs-right`">
                    <span v-if="props.item.TypeDescription == 'Invoice'">{{ account.IsParent ? formatAmount((props.item.Balance + props.item.ChildInvoices.totalBalance)) : formatAmount(props.item.Balance) }}</span>
                  </td>
                  <td class="text-xs-right actions-column" width="253px">
                    <v-layout :class="props.item.TypeDescription == 'Invoice' && props.item.Balance > 0 && account.IsParent ? 'ma-0 pa-0' : ''">
                      <v-flex xs12 :class="props.item.TypeDescription == 'Invoice' && (props.item.Balance > 0 || props.item.ChildInvoices.totalBalance > 0) && account.IsParent ? 'ma-0 px-0 pb-0' : ''">
                        <v-btn
                          class="PdfButton"
                          color="primary"
                          v-if="props.item.TypeDescription == 'Invoice'"
                          @click="downloadInvoicePdf(props.item.DocId)"
                        >PDF</v-btn>
                        <v-btn
                          v-if="clientName == 'Atlas' && props.item.TypeDescription == 'Invoice' && props.item.Balance > 0"
                          class="SolidButton"
                          @click="openPayNow(props.item)"
                        >Pay Now</v-btn>
                      </v-flex>
                    </v-layout>
                    <v-layout v-if="clientName == 'Atlas' && props.item.TypeDescription == 'Invoice' && (props.item.ChildInvoices.totalBalance > 0) && account.IsParent" class="ma-0 pa-0">
                      <v-flex xs12 class="ma-0 px-0 py-2">
                        <v-btn
                          class="SolidButton"
                          @click="openPayNowChildren(props.item)"
                        >Pay Now With Children</v-btn>
                      </v-flex>
                    </v-layout>
                  </td>
                </tr>
              </template>
              <template v-slot:expand="props">
                <child-invoices-table ref="childInvoicesTable" :item="props.item" :clientToken="clientToken" :accountToken="accountToken" :BAN="BAN" :parentName="account.Name1" :emailAddress="account.contacts[0].ACEMAIL" :userName="userName" />
              </template>
              <template v-slot:actions-append>
                <v-layout class="actions-append">
                  <v-flex xs7>
                    <data-table-pagination :rowsPerPage="pagination.rowsPerPage" :totalRecords="pagination.totalRecords" v-model="pagination.page" @input="getBillingHistory()"></data-table-pagination>
                  </v-flex>
                  <v-flex xs2 class="total-amount text-xs-right" pr-4>
                    {{ formatAmount(totalAmount) }}
                  </v-flex>
                  <v-flex>
                    <v-layout v-if="clientName == 'Atlas'" column class="text-xs-right">
                      <v-flex v-if="totalAmount > 0" :style="account.IsParent ? '' : 'padding-top: 11px'">
                        <v-btn
                          class="SolidButton"
                          @click="openPayAll()"
                        >Pay All</v-btn>
                      </v-flex>
                      <v-flex v-if="account.IsParent && totalAmount > 0" pt-2>
                        <v-btn
                          class="SolidButton"
                          @click="openPayAllChildren()"
                        >Pay All With Children</v-btn>
                      </v-flex>
                    </v-layout>
                    <v-layout v-if="clientName == 'Mircom'" column class="text-xs-right">
                      <v-flex v-if="totalAmount > 0" style="padding-top: 11px">
                        <v-btn
                          class="SolidButton"
                          @click="openMircomPayNow()"
                        >Pay Now</v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </template>
              <template v-slot:no-data>
                <td colspan="4" class="text-md-center"><span color="success">No invoices for this account.</span></td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import axios from "axios"
import store from "../../store"
import dayjs from 'dayjs'
import TheLoading from "@/components/TheLoading.vue"
import ChildInvoicesTable from "@/components/PaymentConsole/ChildInvoicesTable.vue"
import DataTablePagination from "../../components/PaymentConsole/DataTablePagination.vue"

export default {
  components: {
    /* eslint-disable vue/no-unused-components */
    TheLoading,
    ChildInvoicesTable,
    DataTablePagination
  },
  data: () => ({
    valid: false,
    menu: false,
    BAN: "",
    accountToken: "",
    clientToken: "",
    clientName: "",
    boss40AppUrl: "",
    boss40ApiUrl: "",
    userName: "",
    account: {},
    billingHistory: [],
    arMode: null,
    total: 0,
    totalAmount: 0,
    pagination: {
      rowsPerPage: 10,
      descending: true,
      totalRecords: 0,
      sortBy: 'Date',
      page: 1
    },
    filters: {
      AccountBAN: null,
      Type: null
    },
    searchFilter: "",
    
    headers: [
      {
        text: "Reference",
        value: "Reference",
        width: '10%',
        class: 'font-weight-bold subheading text-xs-left'
      },
      {
        text: "Type",
        value: "Type",
        width: '10%',
        class: 'font-weight-bold subheading text-xs-center'
      },
      {
        text: "Date",
        value: "invoiceDate",
        width: '15%',
        class: 'font-weight-bold subheading text-xs-left'
      },
      {
        text: "Status",
        value: "Status",
        width: '10%',
        class: 'font-weight-bold subheading text-xs-center'
      },
      {
        text: "Original Amount",
        value: "OriginalAmount",
        width: '10%',
        class: 'font-weight-bold subheading text-xs-right'
      },
      {
        text: "Balance",
        value: "Balance",
        width: '10%',
        class: 'font-weight-bold subheading text-xs-right'
      },
      {
        text: "Actions",
        value: "Actions",
        width: '10%',
        class: 'font-weight-bold subheading text-xs-right'
      }
    ],
  }),
  async mounted() {
    this.$refs.loading.loading(true)
    this.accountToken = this.$route.query.token
    await this.decodeBoss40Token()
    await this.getBoss40Urls()
    await this.getBillingHistory()
    this.$refs.loading.loading(false)
  },
  watch: {},
  computed: {},
  methods: {
    async decodeBoss40Token(){
      await axios
        .post(process.env.VUE_APP_PGAPI + `api/boss40/DecodeMyAccountToken/?token=${this.accountToken}`)
        .then((resp) => {
          this.clientToken = resp.data.PaygateToken
          this.BAN = resp.data.AccountNumber
          store.state.BAN = resp.data.AccountNumber
          this.account = resp.data.Account
          this.userName = resp.data.UserName
          this.arMode = resp.data.ArMode
        })
        if (this.account.IsParent) {
          this.headers.splice(5, 0, {
            text: "Children Total",
            value: "ChildrenTotal",
            width: '10%',
            class: 'font-weight-bold subheading text-xs-right'
          })
        }
    },
    async getBoss40Urls() {
      await axios.post(process.env.VUE_APP_PGAPI + 'api/config/getboss40urls',{ "clientToken": this.clientToken })
      .then((resp) => {
        this.boss40AppUrl = resp.data.Boss40AppUrl
        this.boss40ApiUrl = resp.data.Boss40ApiUrl
        this.clientName = resp.data.ClientName
      });
    },
    async getBillingHistory() {
      if (this.clientToken) {
        this.$refs.loading.loading(true)
        this.filters.AccountBAN = this.BAN
        this.filters.isParent = this.account.IsParent
        this.filters.arMode = this.arMode
        let postDataObject = {
          payGateToken: this.clientToken,
          postData:{
            pagination: this.pagination,
            filtersChanged: true,
            filters: this.filters,
            search: this.searchFilter
          }
        }
        let response = await axios.post(process.env.VUE_APP_PGAPI + 'api/boss40/getbillinghistory/', postDataObject)
        if (response) {
          this.billingHistory = response.data.receivables
          this.pagination = response.data.pagination
          this.total = response.data.pagination.totalRecords
          this.totalAmount = response.data.totalBalance

          this.setStatusDetails()
        }
        this.$refs.loading.loading(false)
      }
    },
    async openPayNow(item) {
      let params = {
        invoiceDetails: {...item},
        accountToken: this.accountToken,
        clientToken: this.clientToken,
        clientName: this.clientName,
        BAN: this.BAN,
        emailAddress: this.account.contacts[0].ACEMAIL,
        userName: this.userName,
        account: this.account
      }
      store.commit('SET_ADMIN_PAY_CONSOLE_DETAILS', params)
      this.$router.push({
        name: 'AdminPayNow',
      })
    },
    async openMircomPayNow() {
      let params = {
        accountToken: this.accountToken,
        clientToken: this.clientToken,
        clientName: this.clientName,
        BAN: this.BAN,
        emailAddress: this.account.contacts[0].ACEMAIL,
        userName: this.userName,
        account: this.account,
        totalAmount: this.totalAmount
      }
      store.commit('SET_ADMIN_PAY_CONSOLE_DETAILS', params)
      this.$router.push({
        name: 'AdminPayNow',
      })
    },
    async openPayNowChildren(item) {
      let invoices = item.ChildInvoices.receivables.filter((i) => i.Balance > 0)
      if (item.Balance > 0) invoices.unshift(item)
      let params = {
        invoiceDetails: invoices,
        accountToken: this.accountToken,
        clientToken: this.clientToken,
        isParent: true,
        BAN: this.BAN,
        emailAddress: this.account.contacts[0].ACEMAIL,
        userName: this.userName,
        name: this.account.Name1,
        account: this.account
      }
      store.commit('SET_ADMIN_PAY_CONSOLE_DETAILS', params)
      this.$router.push({
        name: 'AdminPayAll',
      })
    },
    async openPayAll() {
      let response = await axios.get(process.env.VUE_APP_PGAPI + `api/boss40/getallopeninvoices/${this.BAN}/${this.clientToken}`)
      let params = {
        invoiceDetails: {...response.data.receivables},
        accountToken: this.accountToken,
        clientToken: this.clientToken,
        isParent: false,
        BAN: this.BAN,
        emailAddress: this.account.contacts[0].ACEMAIL,
        userName: this.userName,
        name: this.account.Name1,
        account: this.account
      }
      store.commit('SET_ADMIN_PAY_CONSOLE_DETAILS', params)
      this.$router.push({
        name: 'AdminPayAll',
      })
    },
    async openPayAllChildren() {
      let response = await axios.get(process.env.VUE_APP_PGAPI + `api/boss40/getallopeninvoicesforparent/${this.BAN}/${this.clientToken}`)
      let params = {
        invoiceDetails: {...response.data.receivables},
        accountToken: this.accountToken,
        clientToken: this.clientToken,
        isParent: true,
        BAN: this.BAN,
        emailAddress: this.account.contacts[0].ACEMAIL,
        userName: this.userName,
        name: this.account.Name1,
        account: this.account
      }
      store.commit('SET_ADMIN_PAY_CONSOLE_DETAILS', params)
      this.$router.push({
        name: 'AdminPayAll',
      })
    },
    async downloadInvoicePdf(docId) {
      let response = await axios.get(process.env.VUE_APP_PGAPI + `api/boss40/downloadinvoicepdf/${docId}/${this.clientToken}`, { responseType: 'blob' })
      const url = window.URL.createObjectURL(new Blob([response], {type: 'application/pdf'}))
      const link = document.createElement('a')
      link.href = url
      link.target = "_blank"
      link.setAttribute('inline', docId + '.pdf')
      document.body.appendChild(link)
      link.click()
    },
    cancel() {
      location.assign(`${this.boss40AppUrl}#/account-summary/${this.BAN}`)
    },
    deleteQueryString(queryString) {
      let query = Object.assign({}, this.$route.query);
      delete query[`${queryString}`];
      this.$router.replace({ query });
    },
    formatDate(date) {
      if (date) {
        let YYYYMMDD = dayjs(date, 'YYYY-MM-DD')
        return YYYYMMDD.format('DD MMM, YYYY')
      }
    },
    formatAmount(value) {
      var absValue = Math.abs(value)
      var returnString = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(absValue)
      return value < 0 ? `(${returnString})` : returnString
    },
    getTypeColour(item) {
      switch(item) {
        case 'Invoice':
          return 'primary'
        case 'Payment':
          return 'green'
        case 'Credit Note':
          return 'error'
        case 'Debit Note':
          return 'secondary'
        case 'Interest':
          return 'secondary'
      }
    },
    setStatusDetails() {
      this.billingHistory.forEach((item) => {
        switch (item.TypeDescription) {
          case 'Payment':
            switch (item.Status) {
              case 'C4':
              case 'C5':
              case 'C6':
                item.StatusDetails = 'Applied'
                item.StatusColor = 'primary'
                break
              case 'A0':
                item.StatusDetails = 'Pending'
                item.StatusColor = 'error'
                break
              case 'E1':
                item.StatusDetails = 'Failed'
                item.StatusColor = 'error'
                break
            }
            break
          case 'Invoice':
            if (item.Balance <= 0) {
              item.StatusDetails = 'Paid'
              item.StatusColor = 'success'
            } else if (item.Balance > 0 && item.Balance != item.Amount) {
              item.StatusDetails = 'Partially Paid'
              item.StatusColor = 'warning'
            } else {
              item.StatusDetails = 'Posted'
              item.StatusColor = 'warning'
            }
            break
          case 'Credit Note':
            item.StatusDetails = 'Posted'
            item.StatusColor = 'success'
            break
          case 'Debit Note':
            item.StatusDetails = 'Posted'
            item.StatusColor = 'success'
            break
          case 'Interest':
            item.StatusDetails = 'Posted'
            item.StatusColor = 'success'
            break
        }
      })
    }
  },
};
</script>

<style scoped>
.app {
  width: 100%;
  background-color: #EEEEEE !important;
}
.content {
  position: relative;
  min-width: 1300px !important;
  width: 60%;
  margin: 0 auto;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: white;
}
.table-card {
  position: relative;
  min-width: 1300px !important;
  width: 70%;
  margin: 0 auto;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: white;
}
.white-chip {
  height: 20px;
  color: white !important;
  font-size: 70%;
  font-weight: bold;
  padding-bottom: 0px;
  margin-top: -2px;
  margin-bottom: -1px;
}
.total-amount {
  color: black !important;
  font-size: 14px;
  font-weight: 600;
  padding-top: 11px !important;
}
.SolidButton {
  height: 20px;
  min-width: 50px !important;
  margin-top: -2px;
  margin-bottom: -2px;
  border-color: #4caf50 !important;
  border-width: 1px !important;
  border-style: solid !important;
  color: white !important;
  box-shadow: none !important;
  background-color: #4caf50 !important;
}
.BorderButton {
  height: 20px;
  min-width: 50px !important;
  margin-top: -2px;
  margin-bottom: -2px;
  border-color: #4caf50 !important;
  border-width: 1px !important;
  border-style: solid !important;
  color: #4caf50 !important;
  box-shadow: none !important;
  background-color: transparent !important;
}
.PdfButton {
  height: 20px;
  min-width: 50px !important;
  margin-top: -2px;
  margin-bottom: -2px;
  border-color: #1976d2 !important;
  border-width: 1px !important;
  border-style: solid !important;
  color: #1976d2 !important;
  box-shadow: none !important;
  background-color: transparent !important;
}
/deep/.v-btn--flat:nth-child(2) {
  display: none !important;
}
/deep/.v-btn--flat:nth-child(3) {
  display: none !important;
}
/deep/ .v-datatable__actions__select {
  justify-content: flex-start !important;
  width: 200px !important;
}
/deep/ .v-datatable__actions {
  padding-left: 20px !important;
  display: flex !important;
  justify-content: flex-start !important;
}
/deep/ .v-datatable__actions > div:nth-child(3) {
  width: 900px !important;
  justify-content: flex-end !important;
}
/deep/ .actions-append {
  width: 913px !important;
  justify-content: flex-end !important;
}
</style>