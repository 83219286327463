<template>
  <v-app class="app">
    <the-loading ref="loading" />
    <my-account-40-header
      class="add-card-header"
      :account="account"
      :firstName="firstName"
      :lastName="lastName"
      :clientName="clientName"
      :myAccountAppUrl="myAccountAppUrl"
      :invoiceToken="invoiceToken"
      :myAccountToken="myAccountToken"
      :showACH="showACH"
      :hasLinkedAccounts="hasLinkedAccounts"
    />
    <v-container class="content" fluid pa-4 fill-height>
      <v-flex align-self-start>
        <v-layout row fill-height class="justify-center text-xs-center">
          <v-spacer></v-spacer>
          <v-flex xs8>
            <v-card flat>
              <v-flex xs12 class="justify-center text-xs-center">
                <h1>Add Credit Card</h1>
              </v-flex>
              <v-card-text v-if="!loading" style="height:100%">
                <v-form
                  v-if="!showStripeForm"
                  v-model="valid"
                  @submit="submit"
                  ref="addCardForm"
                  lazy-validation
                  style="height:100%"
                >
                  <v-layout row>
                    <v-flex xs6 mr-3>
                      <v-text-field
                        name="ccNumber"
                        v-model="ccNumber"
                        mask="credit-card"
                        label="Card Number"
                        :rules="cardNumberRules"
                        box
                        background-color="#e8f0fe"
                      />
                    </v-flex>
                    <v-flex xs6 ml-3>
                      <v-menu
                        v-model="menu"
                        :close-on-content-click="true"
                        :nudge-right="40"
                        lazy
                        transition="scale-transition"
                        offset-y
                        full-width
                        min-width="290px"
                      >
                        <v-text-field
                          slot="activator"
                          v-model="ccExpiry"
                          label="Expiry (MM/YY)"
                          readonly
                          required
                          :rules="expiryRules"
                          box
                          background-color="#e8f0fe"
                          @focus="menu=true"
                          />
                        <v-date-picker
                          v-model="ccExpiryPicker"
                          type="month"
                          @input="menu = false"
                          :month-format="getMonths"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                  </v-layout>
                  <!-- billing info -->
                  <v-layout row>
                    <v-flex xs3 mr-3>
                      <v-text-field
                        name="ccStreetNumber"
                        v-model="ccStreetNumber"
                        label="Street Number"
                        :rules="streetRequiredRule"
                        box
                        background-color="#e8f0fe"
                      />
                    </v-flex>
                    <v-flex xs5 mx-3>
                      <v-text-field
                        name="ccStreetName"
                        v-model="ccStreetName"
                        label="Street Name"
                        :rules="streetRequiredRule"
                        box
                        background-color="#e8f0fe"
                      />
                    </v-flex>
                    <v-flex xs4 ml-3>
                      <v-text-field
                        name="ccZipPostalCode"
                        v-model="ccZipPostalCode"
                        label="Zip/Postal Code"
                        maxlength="10"
                        required
                        :rules="postalRequiredRule"
                        box
                        background-color="#e8f0fe"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout row v-if="clientName != 'DMP'">
                    <v-flex xs6 mr-3>
                      <v-text-field
                        name="ccEmailAddress"
                        v-model="ccEmailAddress"
                        label="Email Address"
                        :rules="emailRules"
                        box
                        background-color="#e8f0fe"
                      />
                    </v-flex>
                    <v-flex xs6 ml-3>
                      <v-text-field
                        name="ccPhoneNumber"
                        v-model="ccPhoneNumber"
                        label="Phone Number"
                        maxlength="10"
                        required
                        :rules="phoneNumberRequiredRule"
                        box
                        background-color="#e8f0fe"
                      />
                    </v-flex>
                  </v-layout>
                </v-form>
                <add-stripe-form
                  v-if="showStripeForm"
                  ref="stripeForm"
                  :customer="BAN" 
                  :clientToken="clientToken"
                  :firstName="firstName"
                  :lastName="lastName"
                  :email="ccEmailAddress"
                  :myAccountAppUrl="myAccountAppUrl"
                  :invoiceToken="invoiceToken"
                />
                <v-checkbox size="large" class="justify-center text-xs-center pt-4" v-model="defaultPayment" label="Sign up for autopay using this credit card." />
                <p class="text-center red--text">IMPORTANT - Adding a new credit card will replace your existing card if you have one.</p>
                <p>By clicking <b>Save</b> you agree to the <router-link to="/termsconditions">Terms & Conditions</router-link> and <router-link to="/privacypolicy">Privacy Policy.</router-link></p>
                <v-flex>
                  <a @click="cancel">Cancel      </a>
                  <v-btn large color="info" @click="submit">Save</v-btn>
                </v-flex>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-spacer></v-spacer>
        </v-layout>
      </v-flex>
    </v-container>
    <my-account-40-footer class="add-card-footer"></my-account-40-footer>
  </v-app>
</template>

<script>
import axios from "axios";
import moment from "moment";
import store from "../../store";
import MyAccount40Footer from "@/components/MyAccount40/MyAccount40Footer.vue";
import MyAccount40Header from "@/components/MyAccount40/MyAccount40Header.vue";
import RedAsterisk from "@/components/RedAsterisk.vue";
import AddStripeForm from "@/components/MyAccount40/MyAccountStripeForm.vue"
import TheLoading from "@/components/TheLoading.vue"
import Bus from '../../bus'

export default {
  components: {
    /* eslint-disable vue/no-unused-components */
    MyAccount40Footer,
    MyAccount40Header,
    RedAsterisk,
    AddStripeForm,
    TheLoading
  },
  data: () => ({
    valid: false,
    menu: false,
    loading: false,
    BAN: "",
    myAccountToken: "",
    invoiceToken: "",
    clientToken: "",
    clientName: "",
    clientSecret: "",
    myAccountAppUrl: "",
    paymentProvider: "",
    dataKey: "",
    showStripeForm: false,
    showACH: false,
    hasLinkedAccounts: true,
    account: {},
    firstName: "",
    lastName: "",
    ccPhoneNumber: "",
    ccEmailAddress: "",
    ccNumber: "",
    lastFourCC: "",
    ccExpiry: "",
    ccExpiryPicker: "",
    ccMonth: "",
    ccYear: "",
    ccStreetName: "",
    ccStreetNumber: "",
    ccZipPostalCode: "",
    defaultPayment: false,
    phoneNumberMask: "(###) ###-####",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "E-mail must be valid",
    ],
    expiryRules: [
      (v) => !!v || "Expiry is required",
      // (v) => /^[0-9\\-]{7}$/.test(v) || "Invalid Year/Month",
    ],
    postalRequiredRule: [
      (v) => !!v || "Postal/Zip Code is required",
      (v) => /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(v) || /^[0-9]{5}(?:-[0-9]{4})?$/.test(v) || "Postal/Zip Code must be valid"
    ],
    streetRequiredRule: [(v) => !!v || "Street Address is required"],
    streetNumRequiredRule: [(v) => !!v || "Street Number is required"],
    nameRequiredRule: [(v) => !!v || "Required"],
    phoneNumberRequiredRule: [(v) => !!v || "Phone number is required"],
    cvdRules: [
      (v) => !!v || "Card Validation Digits is required",
      (v) =>
        /^[0-9]{3,4}$/.test(v) ||
        "Card Validation Digits requires 3 or 4 digits",
    ],
    cardNumberRules: [
      v => !!v || 'Required.',
      v => /^(?:3[47][0-9]{13})$|(?:4[0-9]{12}(?:[0-9]{3})?)$|(?:5[1-5][0-9]{14})$|(?:6(?:011|5[0-9][0-9])[0-9]{12})$/.test(v) || 'Please use a valid credit card number.'
    ],
    months: [
      "02 - FEB",
      "03 - MAR",
      "04 - APR",
      "05 - MAY",
      "06 - JUN",
      "07 - JUL",
      "08 - AUG",
      "09 - SEP",
      "10 - OCT",
      "11 - NOV",
      "12 - DEC",
      "01 - JAN",
    ],
  }),
  async mounted() {
    this.$refs.loading.loading(true)
    this.loading = true
    this.myAccountToken = this.$route.query.token
    // this.deleteQueryString("token")
    this.invoiceToken = this.$route.query.invoicetoken
    // this.deleteQueryString("invoicetoken")
    await this.decodeMyAccountToken()
    await this.getBoss40AccountDetails()
    await this.getMyAccountUrls()
    await this.checkBusinessRules()
    this.loading = false
    this.$refs.loading.loading(false)
  },
  beforeDestroy(){
    this.ccNumber.destroy();
    this.ccExpiry.destroy();
  },
  watch: {
    ccExpiryPicker() {
      this.ccExpiry = moment(this.ccExpiryPicker).format('MM/YY')
    },
    ccExpiry() {
      this.ccMonth = this.ccExpiry.substring(0, 2)
      this.ccYear = this.ccExpiry.substring(3, 5)
    }
  },
  methods: {
    async submit() {
      if (this.defaultPayment) {
        this.account.paymentMethodId = this.paymentProvider == "Stripe" ? "ST" : "CC"
        await axios.post(process.env.VUE_APP_PGAPI + 'api/boss40/editaccount', { "account": this.account, "payGateToken": this.clientToken })
      }
      if (this.paymentProvider == "Stripe") {
        await this.$refs.stripeForm.submit('addCard')
      } else {
        this.$refs.loading.processing(true)
        this.lastFourCC = this.ccNumber.substring(12, 16)
        if(this.$refs.addCardForm.validate()) {
          let cardDetails = {}
          if (this.paymentProvider == "Paytrace") {
            cardDetails = {
              payGateToken: this.clientToken,
              BAN: this.BAN,
              paytraceCustomer: {
                customer_id: this.account.AccountNumber,
                credit_card: {
                  number: this.ccNumber,
                  expiration_month: this.ccMonth,
                  expiration_year: this.ccYear
                },
                billing_address: {
                  street_address: `${this.ccStreetNumber} ${this.ccStreetName}`,
                  zip: this.ccZipPostalCode,
                  country: this.ccZipPostalCode.length == 6 ? "CA" : "US" 
                }
              }
            }
          } else {
            cardDetails = {
              payGateToken: this.clientToken,
              BAN: this.BAN,
              ccPerson: {
                email: this.ccEmailAddress,
                phoneNumber: this.ccPhoneNumber,
              },
              ccDetails: {
                ccNumber: this.ccNumber,
                ccExpiry: this.ccExpiry,
              },
              ccAddress: {
                streetNumber: this.ccStreetNumber,
                streetName: this.ccStreetName,
                postalZip: this.ccZipPostalCode,
              }
            }
          }
          await axios.post(process.env.VUE_APP_PGAPI + "api/card/post", cardDetails)
          .then((res) => {
            if (res.status == 200) {
              Bus.$emit('alert', {
                color: 'success',
                message: 'Card details saved successfully!'
              })
              location.assign(`${this.myAccountAppUrl}?token=${this.invoiceToken}`)
            } else {
              Bus.$emit('alert', {
                color: 'error',
                message: 'There was an error saving your card details.'
              })
            }
          })
        }
        this.$refs.loading.processing(false)
      }
    },
    async decodeMyAccountToken(){
      await axios
        .post(process.env.VUE_APP_PGAPI + `api/boss40/DecodeMyAccountToken/?token=${this.myAccountToken}`)
        .then((resp) => {
          this.clientToken = resp.data.PaygateToken
          this.BAN = resp.data.AccountNumber
          store.state.BAN = resp.data
          this.clientName = resp.data.Client
          this.ccPhoneNumber = resp.data.PhoneNumber
          this.ccEmailAddress = resp.data.Email
          this.firstName = resp.data.FirstName
          this.lastName = resp.data.LastName
          this.paymentProvider = resp.data.PaymentProvider
        })
        if (this.paymentProvider == "Stripe") this.showStripeForm = true
    },
    async getBoss40AccountDetails(){
      await axios
        .post(process.env.VUE_APP_PGAPI + "api/boss40/getaccount", {"accountNumber" : this.BAN, "payGateToken" : this.clientToken })
        .then((resp) => {
          this.account = resp.data;
          store.state.account = resp.data
        });
    },
    async getMyAccountUrls() {
      await axios
      .post(process.env.VUE_APP_PGAPI + "api/config/getmyaccounturls", {
        clientToken: this.clientToken
      })
      .then((resp) => {
        this.myAccountAppUrl = resp.data.MyAccountAppUrl
        this.clientName = resp.data.ClientName
      })
    },
    async checkBusinessRules() {
      await axios
        .post(process.env.VUE_APP_PGAPI + 'api/boss40/getbusinessrules', { payGateToken: this.clientToken, Filters: { Rules: ["MYACH"] } })
        .then((res) => {
          if (res.data) {
            let achRule = JSON.parse(res.data).find(a => a.Id == "MYACH")
            if (achRule.Value === 'TRUE') this.showACH = true
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    cancel() {
      location.assign(`${this.myAccountAppUrl}?token=${this.invoiceToken}`)
    },
    deleteQueryString(queryString) {
      let query = Object.assign({}, this.$route.query);
      delete query[`${queryString}`];
      this.$router.replace({ query });
    },
    getMonths(month) {
      let i = new Date(month).getMonth(month)
      return this.months[i]
    }
  },
};
</script>

<style scoped>
.app {
  width: 100%;
  background-color: #EEEEEE;
}
.add-card-header {
  min-height: 100px;
  background-color: white;
  min-width: 1133px;
  width: 60%;
  margin: 0 auto;
}
.add-card-footer {
  position: relative !important;
  min-width: 1133px !important;
  width: 60% !important;
  margin: 0 auto;
}
.page-title {
  font-size: x-large;
  font-weight: bold;
}
.content {
  position: relative;
  min-width: 1133px;
  width: 60%;
  margin: 0 auto;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: white;
}
.whitebg {
  background-color: white;
}
.action-bars {
  background-color: #f0f0f0 !important;
  min-height: 52px;
  max-height: 52px;
}
.card-title{
    font-family: Roboto, sans-serif;
    font-size: 20px;
}
.form-field-label{
    color: grey;
    font-family: Roboto, sans-serif;
    font-size: 12px;
}
.form-field{
    border-bottom-width: 1px;
    border-bottom-color: lightgray;
    border-bottom-style: solid;
}
/deep/div.v-input.v-input--selection-controls.v-input--checkbox {
  margin-top: 0px;
  padding-top: 0px;
}
/deep/.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
/deep/.v-label.theme--light {
  color: black;
}
</style>